<template>
  <section class="container pb-8" v-if="isReedy">
    <div class="row">
      <div class="col-12 col-md-11 mx-auto">
        <div class="row">
          <div class="col-12 col-md-4 col-lg-3 mx-auto">
            <div class="bg-outer rounded-sm border-shadow">
              <div
                class="bg-image"
                :style="{
                  paddingBottom: '70%',
                  backgroundImage: 'url(' + TeacherData.teacherImagePathl + ')',
                }"
              ></div>
            </div>
          </div>
          <div class="col-12 col-md-8 col-lg-9 mx-2 mx-md-0">
            <div class="teacher-name mb-3">
              <span>{{ TeacherData.teacherName }}</span>
            </div>
            <div class="row">
              <div class="col-12 col-md-8 mb-3">
                <div class="teacher-title">學經歷</div>
                <p
                  class="px-3"
                  style="white-space: pre-wrap;"
                  v-html="TeacherData.teacherExperience">
                </p>
              </div>
              <div class="col-12 col-md-4">
                <div class="teacher-title">專長與特色</div>
                <p class="px-3"
                  style="white-space: pre-wrap;"
                  v-html="TeacherData.teacherContent"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-11 my-6 my-md-8 mx-auto" v-if="TeacherData.teacherYoutubeUrl">
        <div class="bg-outer rounded-lg border-shadow">
          <div class="videoWrapper videoWrapper100 js-videoWrapper videoWrapperActive">
            <iframe
              class="videoIframe js-videoIframe"
              :src="
                `https://www.youtube.com/embed/${TeacherData.teacherYoutubeUrl}?rel=0&controls=1&enablejsapi=1&origin=http%3A%2F%2Fkai-tech.com.tw&widgetid=3`
              "
              frameborder="0"
              allowTransparency="true"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>

      <div class="col-12" v-if="TeacherCourseData !== undefined && TeacherCourseData.length > 0">
        <h2 class="cs-title my-3 my-md-6" data-style="1">
          <span data-title="精選課程 Hot Course"></span>
        </h2>
        <div class="row">
          <div class="col-lg-11 mx-auto">
            <article class="row cs-card" data-style="4">
              <!-- 課程商品 -->
              <!--todo:商品連結和加入購物車-->
              <div
                v-for="item in TeacherCourseData"
                :key="item.courseId"
                class="col-12 col-md-6 col-lg-3"
              >
                <div class="course__card card card-hover-focus mb-6 mb-md-8">
                  <router-link :to="`/course/classRoom/${item.courseId}`">
                    <div class="course__img bg-outer">
                      <div
                        class="bg-image"
                        :style="{
                          backgroundImage: 'url(' + item.mainImagePath + ')',
                        }"
                      ></div>
                    </div>
                    <div class="course__title">
                      <h3>{{ item.courseName }}</h3>
                    </div>
                    <div class="card-body course__body text-center">
                      <div class="course__price">原價 {{ item.courseOriPrice }}元</div>
                      <div class="course__sale d-block">
                        <i class="fa fa-star mr-1" aria-hidden="true"></i>特價
                        {{ item.coursePrice }}元
                      </div>
                    </div>
                  </router-link>
                  <a class="course__btn-cart" href="" @click.prevent="AddToCar(item.courseId)"
                    >加入購物車</a
                  >
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { GetTeacherById, GetTeacherCourseById } from '@/api/TeachersApi';

export default {
  props: ['currentPage'],
  data() {
    // 建立你的初始化 model data
    return {
      isReedy: false,
      CourseTeacherId: 0,
      TeacherData: {
        teacherId: 0,
        teacherCode: '',
        teacherName: '',
        status: 0,
        teacherContent: '',
        teacherExperience: '',
        teacherYoutubeUrl: '',
        teacherImagePathl: '',
      },
      TeacherCourseData: [],
    };
  },
  created() {
    this.GetTeacher();
  },
  methods: {
    GetTeacher() {
      // 取得 url id 的方法
      const { id } = this.$route.params;
      GetTeacherById(id).then((response) => {
        if (response.data.statusCode === 0) {
          this.TeacherData = response.data.data;
          this.$emit('update:currentPage', {
            name: '',
            title: this.TeacherData.teacherName,
            path: '',
          });
          this.GetTeacherCourse(id);
        }
      });
    },
    GetTeacherCourse(TeacherId) {
      // 取得 url id 的方法
      this.CourseTeacherId = TeacherId;
      GetTeacherCourseById({
        teacherId: this.CourseTeacherId,
        randomCount: 4,
      }).then((response) => {
        if (response.data.statusCode === 0) {
          this.TeacherCourseData = response.data.data;
        }
        this.isReedy = true;
      });
    },
    AddToCar(id) {
      this.$bus.$emit('addShoppingCart', id);
    },
  },
};
</script>

<style></style>

import baseAxios from '@/api/base';

baseAxios.defaults.baseURL = `${process.env.VUE_APP_APIPATH}`;

/** product api */
/**
 * 取得所有有效講師列表
 * @param {object} data {
  "pageIndex": 0,
  "pageSize": 0,
  "data": {}
  }
 */
export const GetTeachers = (data) => baseAxios.post('/FrontEnd/Teachers/Teachers', data);

/**
 * 取得講師資料
 * @param {object} data { id }
 */
export const GetTeacherById = (id) => baseAxios.post('/FrontEnd/Teachers/TeacherById', { id });

/**
 * 隨機取得講師課程資料
 * @param {object} data { teacherId, randomCount }
 */
export const GetTeacherCourseById = (data) => baseAxios.post('/FrontEnd/Teachers/TeacherCourseById', data);
